import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import setSpacesInText from '../../functions/setSpacesInText';

import Button from '../../components/Button.jsx';
import Link from '../../components/Link.jsx';
import IndexBack from '../../components/IndexBack.jsx';

class IndexHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    timers = [];

    startAnimate() {
        const mainBlock = this.parent.current.querySelector('.indexHeader__topBlock._main');
        const mainBlockInner = mainBlock.querySelector('.indexHeader__topBlockInner');
        const subBlock = this.parent.current.querySelector('.indexHeader__topBlock._sub');
        const subBlockInner = subBlock.querySelector('.indexHeader__topBlockInner');
        const back = this.parent.current.querySelector('.indexHeader__back');
        const button = this.parent.current.querySelector('.indexHeader__contentButton');
        const date = this.parent.current.querySelector('.indexHeader__contentDate');
        const steps = this.parent.current.querySelector('.indexHeader__steps');
        const step1 = steps.querySelector('.indexHeader__step._1');
        const step2 = steps.querySelector('.indexHeader__step._2');
        const step3 = steps.querySelector('.indexHeader__step._3');

        mainBlock.classList.remove('_hide');

        this.timers.push(
            setTimeout(() => {
                mainBlockInner.classList.remove('_hide');
            }, 50),
            setTimeout(() => {
                subBlock.classList.remove('_hide');
            }, 100),
            setTimeout(() => {
                subBlockInner.classList.remove('_hide');
            }, 150),
            setTimeout(() => {
                button.classList.remove('_hide');
            }, 250),
            setTimeout(() => {
                date.classList.remove('_hide');
            }, 300),
            setTimeout(() => {
                back.classList.remove('_hide');
            }, 400),
            setTimeout(() => {
                steps.classList.remove('_hide');
            }, 400),
            setTimeout(() => {
                step1.classList.remove('_hide');
            }, 450),
            setTimeout(() => {
                step2.classList.remove('_hide');
            }, 500),

            setTimeout(() => {
                step3.classList.remove('_hide');
            }, 550),
        );
    }

    isReady = false;

    checkReady() {
        const { components } = this.props;

        if (components && !this.isReady) {
            this.isReady = true;

            setTimeout(() => {
                this.startAnimate();
            }, 300);
        }
    }

    componentDidMount() {
        this.checkReady();
    }

    componentDidUpdate() {
        this.checkReady();
    }

    componentWillUnmount() {
        this.timers.forEach((timer) => {
            clearTimeout(timer);
        });
    }

    render() {
        const { components, user } = this.props;
        const anounce = components?.anounce;

        return (
            <>
                <div
                    ref={this.parent}
                    className={`indexHeader ${components ? '_ready' : ''}`}
                    id="index"
                >
                    <div className="indexHeader__inner">
                        <div className="indexHeader__back _hide">
                            <IndexBack />
                        </div>
                        <div className="indexHeader__top">
                            <div className="indexHeader__topBlock _main _hide">
                                <div className="indexHeader__topBlockInner _hide">
                                    Выиграй поездку
                                </div>
                            </div>
                            <div className="indexHeader__topBlock _sub _hide">
                                <div className="indexHeader__topBlockInner _hide">
                                    на финал RDS GP
                                </div>
                            </div>
                        </div>
                        <div className="indexHeader__content">
                            <div className="indexHeader__contentBox">
                                <Link
                                    className="indexHeader__contentButton _hide"
                                    pageName={user ? 'cheques' : 'login'}
                                >
                                    <Button className="_white">{anounce?.button?.title}</Button>
                                </Link>
                                <p className="indexHeader__contentDate _hide">
                                    {anounce?.period?.title}
                                </p>
                            </div>
                            <div className="indexHeader__steps _hide">
                                <div className="indexHeader__step _hide _1">
                                    <img
                                        src={require('../../media/step-1.svg').default}
                                        alt=""
                                        className="indexHeader__stepIcon"
                                    />
                                    <div className="indexHeader__stepTitle">
                                        {anounce?.step1?.title}
                                    </div>
                                    <p className="indexHeader__stepDescription">
                                        {anounce?.step1?.description}
                                    </p>
                                </div>
                                <div className="indexHeader__step _hide _2">
                                    <img
                                        src={require('../../media/step-2.svg').default}
                                        alt=""
                                        className="indexHeader__stepIcon"
                                    />
                                    <div className="indexHeader__stepInfo">
                                        {anounce?.step2?.subtitle}
                                    </div>
                                    <div className="indexHeader__stepTitle">
                                        <Link tag="span" pageName={user ? 'cheques' : 'login'}>
                                            Регистрируй
                                        </Link>{' '}
                                        чеки
                                    </div>
                                    <p className="indexHeader__stepDescription">
                                        {anounce?.step2?.description}
                                    </p>
                                </div>
                                <div className="indexHeader__step _hide _3">
                                    <img
                                        src={require('../../media/step-3.svg').default}
                                        alt=""
                                        className="indexHeader__stepIcon"
                                    />
                                    <div className="indexHeader__stepTitle">
                                        {anounce?.step3?.title}
                                    </div>
                                    <p className="indexHeader__stepDescription">
                                        {anounce?.step3?.description}
                                    </p>
                                </div>
                            </div>
                            <div
                                className="indexHeader__support"
                                dangerouslySetInnerHTML={{
                                    __html: setSpacesInText(anounce?.dislaimer?.description),
                                }}
                            ></div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
    };
}

export default connect(mapStateToProps)(IndexHeader);

IndexHeader.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    components: PropTypes.object,
};
